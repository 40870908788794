import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import Glide, {
  Controls,
  Breakpoints,
  Keyboard,
} from "@glidejs/glide/dist/glide.modular.esm";
import "@glidejs/glide/dist/css/glide.core.css";

const GlideArrowMargin = "1rem";

const GlideSlides = styled.ul`
  margin: 0;
  padding: 0;
`;

const GlideArrow = styled.button`
  position: absolute;
  box-sizing: border-box;
  width: 4rem;
  height: 4rem;
  top: 50%;
  left: ${(props) => (props.left ? GlideArrowMargin : "auto")};
  right: ${(props) => (props.left ? "auto" : GlideArrowMargin)};
  appearance: none;
  background-color: transparent;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.5rem;
  outline: 0;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  z-index: 2;

  &:hover {
    transform: translateY(calc(-50% - 2px));
  }

  &:active {
    transform: translateY(-50%);
  }
`;

class Carousel extends React.Component {
  constructor() {
    super();
    this.glideId = `glide-123`;
  }

  componentDidMount() {
    if (typeof window !== "undefined" && this.props.photos !== null) {
      const options = {
        type: "carousel",
        gap: 0,
        perView: 1,
        peek: 120,
        breakpoints: {
          768: {
            peek: 0,
          },
        },
        ...(this.props.options || {}),
      };

      new Glide(`#${this.glideId}`, options).mount({
        Controls,
        Breakpoints,
        Keyboard,
      });
    }
  }

  render() {
    if (this.props.photos === null) {
      return null;
    }

    return (
      <div className="glide" id={this.glideId}>
        <div className="glide__track" data-glide-el="track">
          <GlideSlides className="glide__slides">
            {this.props.photos.map((photo) => (
              <li
                className="glide__slide"
                key={photo.localFile.id || Math.random()}
              >
                <Img
                  loading="lazy"
                  fadeIn={false}
                  fluid={photo.localFile.childImageSharp.fluid}
                  alt={photo.alt_text}
                />
              </li>
            ))}
          </GlideSlides>
        </div>

        <div className="glide__arrows" data-glide-el="controls">
          <GlideArrow
            left
            type="button"
            className="glide__arrow glide__arrow--left"
            data-glide-dir="<"
          >
            ◀︎
          </GlideArrow>
          <GlideArrow
            type="button"
            className="glide__arrow glide__arrow--right"
            data-glide-dir=">"
          >
            ▶︎
          </GlideArrow>
        </div>
      </div>
    );
  }
}

export default Carousel;
