/* eslint-disable react/no-find-dom-node */
import React from 'react';

class Iframe extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: '600px',
    };

    this.iframeRef = React.createRef();
    this.handleIframeTask = this.handleIframeTask.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('message', this.handleIframeTask);
    }
  }

  handleIframeTask(e) {
    if (e.origin !== 'https://cms.chaloupkakteraexistuje.cz') {
      return;
    }
    if (e.data.height) {
      this.setState({
        height: e.data.height,
      });
    }
  }

  render() {
    return (
      <>
        {this.props.src && (
          <iframe
            ref={this.iframeRef}
            width="100%"
            height={this.state.height}
            frameBorder="0"
            title="Rezervace"
            src={this.props.src}
          />
        )}
      </>
    );
  }
}

export default Iframe;
